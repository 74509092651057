import api from "@/api";
import Card from "@/components/Card";
import { MoneyInfoParam } from "@/models/order";
import { OptionsRow, TableRow } from "@/models/type";
import { ElNotification } from "element-plus";
import { Options, Vue } from "vue-class-component";
import Order from ".";

@Options({
  props: {
    detailId: {
      type: Number,
      default: 0
    }
  },
  watch: {
    detailId: {
      deep: true,
      immediate: true,
      handler(val, oldVal) {
        api.order(val).then(res => {
          this.orderForm = res.data
          this.submitForm.autoConfirmDay = res.data.autoConfirmDay
          this.submitForm.deliveryCompany = res.data.deliveryCompany
          this.submitForm.deliverySn = res.data.deliverySn
        })
      }
    }
  }
})
export default class OrderDetail extends Vue {
  public detailId = 0
  submitForm: MoneyInfoParam = {
    autoConfirmDay: 0,
    deliveryCompany: '',
    deliverySn: ''
  }

  goBack(): void {
    if(this.$route.query?.id) {
      this.$router.push({
        name: 'ClientOneVip'
      })
    } else {
      const __parent = this.$parent as Order
      __parent.type = ''
    }
  }

  public orderForm: any = {}

  public payTypeList: OptionsRow[] = [
    { name: '微信支付', value: 2 },
    { name: '支付宝支付', value: 1 },
    { name: 'PayMe支付', value: 5 },
    { name: 'VISA支付', value: 3 },
    { name: 'Master支付', value: 4 }
  ]

  public list: Array<TableRow> = [
    {label: '商品编号', prop: 'productId'},
    {label: '商业首图', slot: {
      default: scope => <img class="order-img" src={scope.row.productPic} alt="" />
    }},
    {label: '商品名称', prop: 'productName'},
    {label: '货号', prop: 'productSn'},
    {label: '价格', prop: 'productPrice'},
  ]

  public feeList: Array<TableRow> = [
    {label: '商品合计', slot: {
      default: () => <span>{this.orderForm.orderItemList?.reduce((preV, curV) => { return preV + (curV.productPrice * curV.productQuantity)}, 0)}{this.orderForm.orderType ? '积分' : 'HK$'}</span>
    }},
    {label: '运费', slot: {
      default: () => <span>{this.orderForm.freightAmount || 0}</span>
    }},
    {label: '代金券', slot: {
      default: () => <span>{this.orderForm.couponAmount || '-0'}</span>
    }},
    {label: '实付金额', slot: {
      default: () => <span>{this.orderForm.payAmount}{this.orderForm.orderType ? '积分' : 'HK$'}</span>
    }}
  ]

  submit(): void {
    const obj = Object.assign({}, this.submitForm)
    obj.orderId = this.orderForm.id
    api.orderUpdateMoneyInfo(obj).then(() => {
      ElNotification({
        type: 'success',
        message: '保存成功'
      })
    })
  }

  render(): JSX.Element {
    const slot = {
      suffix: () => <span>天</span>
    }
    return <div class="order">
      <el-form
        model={this.submitForm}
      >
        <Card title="基础信息" class="order-card" back onGoBack={this.goBack}>
          <div class="order-card-row">
            <el-row gutter={ 30 }>
              <el-col span={8}>
                <el-form-item label="订单编号">
                  <span>{this.orderForm.orderSn}</span>
                </el-form-item>
              </el-col>
              <el-col span={8}>
                <el-form-item label="下单账号">
                  <span>{this.orderForm.memberUsername}</span>
                </el-form-item>
              </el-col>
              <el-col span={8}>
                <el-form-item label="支付方式">
                  <span>{
                    this.payTypeList.find(item => item.value === this.orderForm.payType)?.name
                  }</span>
                </el-form-item>
              </el-col>
            </el-row>
            <el-row gutter={ 30 }>
              <el-col span={8}>
                <el-form-item label="物流公司">
                  <el-input v-model={this.submitForm.deliveryCompany} placeholder="物流公司" clearable />
                </el-form-item>
              </el-col>
              <el-col span={8}>
                <el-form-item label="物流单号">
                  <el-input v-model={this.submitForm.deliverySn} placeholder="物流单号" clearable />
                </el-form-item>
              </el-col>
              <el-col span={8}>
                <el-form-item label="自动收货">
                  <el-input  v-model={this.submitForm.autoConfirmDay} v-slots={slot} placeholder="自动收货" clearable />
                </el-form-item>
              </el-col>
            </el-row>
            <div class="order-card-submit">
              <div onClick={this.submit}>保存</div>
            </div>
          </div>
        </Card>
        <Card title="收件人信息" class="order-card">
          <div class="order-card-row">
            <el-row gutter={ 30 }>
              <el-col span={8}>
                <el-form-item label="收件人">
                  <span>{this.orderForm.receiverName}</span>
                </el-form-item>
              </el-col>
              <el-col span={8}>
                <el-form-item label="手机号">
                  <span>{this.orderForm.receiverPhone}</span>
                </el-form-item>
              </el-col>
            </el-row>
            <el-row gutter={ 30 }>
              <el-col span={8}>
                <el-form-item label="邮编">
                  <span>{this.orderForm.receiverPostCode}</span>
                </el-form-item>
              </el-col>
              <el-col span={8}>
                <el-form-item label="地址">
                  <span>{this.orderForm.receiverProvince} {this.orderForm.receiverRegion} {this.orderForm.receiverCity} {this.orderForm.receiverDetailAddress}</span>
                </el-form-item>
              </el-col>
            </el-row>
          </div>
        </Card>
        <Card title="商品信息" class="order-card">
          <el-table
            data={this.orderForm.orderItemList}
          >
            {this.list.map(item => (
              <el-table-column
                prop={ item.prop }
                label={ item.label }
                align={ item.align || 'center' }
                v-slots={ item.slot }
              />
            ))}
          </el-table>
        </Card>
        <Card title="费用信息" class="order-card">
          <el-table
            data={[{}]}
          >
            {this.feeList.map(item => (
              <el-table-column
                prop={ item.prop }
                label={ item.label }
                align={ item.align || 'center' }
                v-slots={ item.slot }
              />
            ))}
          </el-table>
        </Card>
      </el-form>
    </div>
  }
}
