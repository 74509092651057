import Clipboard from 'clipboard'
import { ElNotification } from 'element-plus'

function clipboardSuccess(): void {
  ElNotification({
    message: '复制成功',
    type: 'success'
  })
}

function clipboardError(): void {
  ElNotification({
    message: '复制失败',
    type: 'error'
  })
}

export default function handleClipboard(text: string, event: Event): void {
  const clipboard = new Clipboard(event.target as Element, {
    text: () => text
  }) as any
  clipboard.on('success', () => {
    clipboardSuccess()
    clipboard.destroy()
  })
  clipboard.on('error', () => {
    clipboardError()
    clipboard.destroy()
  })
  clipboard.onClick(event)
}
