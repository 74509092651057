import api from "@/api"
import Card from "@/components/Card"
import Pagination from "@/components/Pagination"
import Search from "@/components/Search"
import { OptionsRow, PaginationRow, SearchListRow, TableRow } from "@/models/type"
import { debounceClick, parseTime } from "@/utils"
import handleClipboard from "@/utils/clipboard"
import { ElNotification } from "element-plus"
import { ElFormItemContext } from "element-plus/lib/el-form"
import { h } from 'vue'
import { Vue } from "vue-class-component"
import OrderDetail from "./detail"
import './index.scss'

export default class Order extends Vue {

  private searchObj: any = {}

  public shipShow = false
  public shipId = 0
  public shipDetailRow: any = {}

  public locusShow = false
  public locusDetailRow: any = {}

  public shipForm: {deliveryCompany: string, deliverySn: string, orderId: number} = {
    deliveryCompany: '',
    deliverySn: '',
    orderId: 0
  }
  public shipFormRules = {
    deliveryCompany: [
      { required: true, message: '请输入物流公司' }
    ],
    deliverySn: [
      {required: true, message: '请输入物流单号' }
    ]
  }

  private total = 0
  srcNum = 0
  switchNum = 0
  public url = ''
  public type = ''
  public detailId = 0

  public srcList: Array<string> = []

  public orderStatusList: OptionsRow [] = [
    { name: '待付款', value: 0 },
    { name: '待发货', value: 1 },
    { name: '已发货', value: 2 },
    { name: '已完成', value: 3 },
    { name: '已关闭', value: 4 },
    { name: '无效订单', value: 5 },
    { name: '已评价', value: 6 },
  ]

  public payTypeList: OptionsRow[] = [
    { name: '微信支付', value: 2 },
    { name: '支付宝支付', value: 1 },
    { name: '积分支付', value: 5 },
    // { name: 'PayMe支付', value: 5 },

    { name: 'VISA支付', value: 3 },
    { name: 'Master支付', value: 4 }
  ]

  private tableData: Array<any> = []
  private tableList: Array<TableRow> = [
    { label: '序号', slot: {
      default: (scope: any) => <span>{scope.$index + 1}</span>
    } },
    { label: '订单编号', prop: 'orderSn' },
   /*  { label: '商品首图', slot: {
      default: (scope: any) => <img class="mall-img pointer" src={ scope.row.goodsPic } alt={ scope.row.title } onClick={() => this.imgShow(scope.row.goodsPic)} />
    } }, */
    { label: '收货人', prop: 'receiverName' },
    { label: '定价/实付', slot: {
      default: (scope: any) => <span>{scope.row.totalAmount} / {scope.row.payAmount}</span>
    } },
    { label: '支付方式', prop: 'payType', slot: {
      default: (scope: any): JSX.Element => {
        return scope.row.orderType
          ? <span>积分兑换</span>
          : <span>{ this.payTypeList.find(item => item.value === scope.row.payType)?.name }</span>
      }
    } },
    { label: '创建时间', slot: {
      default: (scope: any) => <span>{ parseTime(new Date(scope.row.createTime).getTime()) }</span>
    } },
    { label: '状态', slot: {
      default: (scope: any) => <span>{this.orderStatusList.find(item => item.value === scope.row.status)?.name}</span>
    } },
    { label: '操作', slot: {
      default: (scope: any): JSX.Element => <div>
        {scope.row.status === 1 && <span class="span-button-primary" onClick={() => this.ship(scope.row)}>发货</span>}
        {[2, 3].includes(scope.row.status) && <span class="span-button-primary" onClick={() => this.locus(scope.row)}>轨迹</span>}
        <span class="span-button-primary" onClick={() => this.see(scope.row.id)} >查看</span>
      </div>
    } }
  ]

  private searchList: Array<SearchListRow> = [
    { label: '订单编号', type: 'input', placeholder: '请输入订单编号', submitKey: 'orderSn' },
    { label: '商品编号', type: 'input', placeholder: '请输入商品编号', submitKey: 'productSn' },
    { label: '收货人姓名/号码', type: 'input', placeholder: '请输入用户编号', submitKey: 'receiverKeyword' },
    { label: '订单状态', type: 'select', placeholder: '请选择订单状态', options: this.orderStatusList, submitKey: 'status' },
    { label: '创建时间', type: 'time',  submitKey: ['startTime', 'endTime'] }
  ]

  locus(row: any): void {
    this.locusDetailRow = row
    this.locusShow = true
  }

  ship(row: any): void {
    this.shipDetailRow = row
    this.shipId = row.id
    this.shipShow = true
  }

  see(id): void {
    this.detailId = id
    this.type = 'detail'
  }

  /**
   * @description 图片dom
   * @author Jinx
   * @date 2021-10-19 19:53:21
   * @returns {*}  {JSX.Element}
   * @memberof Commodity
   */
   imgViewerDom(): JSX.Element {
    return <el-image
      ref="img"
      style="width: 0; height: 0; z-index: 54188;"
      src={ this.url }
      preview-src-list={ this.srcList }
    >
    </el-image>
  }

  /**
   * @description 图片展示
   * @author Jinx
   * @date 2021-08-19 14:27:43
   * @memberof FirstHome
   */
   imgViewerShow(): void {
    const __img =  this.$refs.img as any
    __img.showViewer = true
  }

  imgShow(url: string): void {
    this.url = url
    this.srcList = this.tableData.map(item => item.goodsPic) // 将类型为图片的给srcList
    this.srcNum++
  }

  onSearch(row: never): void {
    this.searchObj = Object.assign({}, row)
    this.getList()
  }

  getList(): void {
    this.tableData = [] // 防止样式不更新 重置一次
    this.$nextTick(() => {
      const __pagination = this.$refs.pagination as any
      const { pageNum, pageSize} = __pagination.paginationObj as PaginationRow
      api.orderList({ pageNum, pageSize, ...this.searchObj }).then(res => {
        this.tableData = res.data.list
        this.total = res.data.total
      })
    })
  }

  tableDom(): JSX.Element {
    return <el-table
      data={ this.tableData }
    >
      {this.tableList.map(item => (
        <el-table-column
          label={ item.label }
          prop={ item.prop }
          v-slots={item.slot}
          align="center"
        />
      ))}
    </el-table>
  }

  shipSubmit(): void {
    const __ship_form = this.$refs.shipform as ElFormItemContext
    __ship_form.validate(v => {
      if(v) {
        const obj = Object.assign({}, this.shipForm)
        obj.orderId = this.shipId
        api.orderUpdateDelivery([obj]).then(res => {
          ElNotification({
            type: 'success',
            title: '成功',
            message: '发货成功'
          })
          this.shipClose()
          this.getList()
        })
      } else {
        return false
      }
    })
  }

  shipClose(): void {
    this.shipShow = false
    this.shipForm = {
      deliveryCompany: '',
      deliverySn: '',
      orderId: 0
    }
  }
  locusClose(): void {
    this.locusShow = false
  }

  locusDom(): JSX.Element {
    const slots = {
      // 对话框底部元素
      footer: () => (
        <span class="dialog-footer">
          <button class="dialog-footer-btn dialog-footer-btn-l" onClick={ this.locusClose }>取 消</button>
          <button class="dialog-footer-btn dialog-footer-btn-r" onClick={ () => debounceClick(this.locusClose) }>确 定</button>
        </span>
      )
    }
    return <el-dialog
      v-model={this.locusShow}
      width="30%"
      title="订单发货"
      v-slots={ slots }
      before-close={ this.locusClose }
    >
      <el-form
        label-width="130px"
        class="el-form-tao"
      >
        <el-form-item label="物流公司">
          <span>{this.locusDetailRow.deliveryCompany}</span>
        </el-form-item>
        <el-form-item label="物流单号">
          <span ref="deliverySn">{this.locusDetailRow.deliverySn}</span>
          {this.locusDetailRow.deliverySn && <span class="pointer" onClick={this.copy} style="color:#409EFF;margin-left: 100px;">复制</span>}
        </el-form-item>
      </el-form>
    </el-dialog>
  }

  copy(e): void {
    handleClipboard(this.locusDetailRow.deliverySn, e)
  }

  shipDom(): JSX.Element {
    const slots = {
      // 对话框底部元素
      footer: () => (
        <span class="dialog-footer">
          <button class="dialog-footer-btn dialog-footer-btn-l" onClick={ this.shipClose }>取 消</button>
          <button class="dialog-footer-btn dialog-footer-btn-r" onClick={ () => debounceClick(this.shipSubmit) }>确 定</button>
        </span>
      )
    }
    return <el-dialog
      v-model={this.shipShow}
      width="30%"
      title="订单发货"
      v-slots={ slots }
      before-close={ this.shipClose }
    >
      <el-form
        ref="shipform"
        model={ this.shipForm }
        rules={ this.shipFormRules }
        label-width="130px"
        class="el-form-tao"
      >
        <el-form-item label="物流公司" prop="deliveryCompany">
          <el-input v-model={ this.shipForm.deliveryCompany } placeholder="请输入物流公司" clearable />
        </el-form-item>
        <el-form-item label="物流单号" prop="deliverySn">
          <el-input v-model={ this.shipForm.deliverySn } placeholder="请输入物流单号" clearable />
        </el-form-item>
        <el-divider />
        <el-form-item label="订单编号">
          <span>{this.shipDetailRow.orderSn}</span>
        </el-form-item>
        <el-form-item label="收货人">
          <span>{this.shipDetailRow.receiverName}</span>
        </el-form-item>
        <el-form-item label="手机号">
          <span>{this.shipDetailRow.receiverPhone}</span>
        </el-form-item>
        <el-form-item label="邮编">
          <span>{this.shipDetailRow.receiverPostCode}</span>
        </el-form-item>
        <el-form-item label="地址">
          <span>{this.shipDetailRow.receiverProvince}
          {this.shipDetailRow.receiverRegion}
          {this.shipDetailRow.receiverCity}
          {this.shipDetailRow.receiverDetailAddress}</span>
        </el-form-item>
      </el-form>
    </el-dialog>
  }

  created(): void {
    if(this.$route.query?.id) {
      this.detailId = +this.$route.query.id
      this.type = 'detail'
    }
  }

  render(): JSX.Element {
    return this.type
      ? h(OrderDetail, {
        detailId: this.detailId
      })
      : <div class="order">
        <Card title="订单查询" class="order-card">
          <Search list={ this.searchList } onSearch={ this.onSearch } class="order-search" />
        </Card>
        <Card title="订单信息">
          {this.tableDom()}
          <Pagination ref="pagination" total={ this.total } onSizeChange={ this.getList } onCurrentChange={ this.getList } />
        </Card>
        {this.imgViewerDom()}
        {this.shipShow && this.shipDom()}
        {this.locusShow && this.locusDom()}
      </div>
  }
  mounted(): void {
    this.getList()
  }
}
